import {useEffect, useState} from "react";
import classNames from "classnames";
import axios from "axios";

const Message = ()=>{
    useEffect(()=>{
        axios.get('/api/message')
            .then( r =>{
                if(r?.data?.status){
                    setMessage(r.data);
                }
            })
            .catch( (e)=>{
            })
    },[])
    const [message, setMessage] = useState(null);
    return <>
        { (message?.status && message?.value) ? <div  className={classNames('dycMsj', message.tipo)}><div className="container dycMsjWrapper" dangerouslySetInnerHTML={{__html: message.value}}/></div> : '' }
    </>
};
export default Message;
