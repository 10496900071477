import {BsPlus} from 'react-icons/bs';
import Link from 'next/link';
import {useState} from "react";
import classNames from "classnames";

const Hamburguer = ({data})=>{
    const [showSubmenu, setShowSubmenu] = useState(null);
    const primary = ()=>{
        if(data?.primary){
            return data.primary.map((i,n)=>{
                return <li key={`menu-${n}`}><a href={i.uri}><span>{i.nombre}</span></a></li>
            });
        }
    };
    const categories = ()=>{
        if(data?.categories?.data){
            return data.categories.data.map((i,n)=>{
                return <li key={`submenu-${n}`}><a href={`${data.categories.path}/${i.uri}`}><span>{i.nombre}</span></a></li>
            })
        }
    };
    const tipos = ()=>{
        if(data?.types?.data){
            return data.types.data.map((i,n)=>{
                return <li key={`submenu-${n}`}><a href={`${data.categories.path}/${i.uri}`}><span>{i.nombre}</span></a></li>
            })
        }
    };
    const ocacion = ()=>{
        if(data?.ocacion?.data){
            return data.ocacion.data.map((i,n)=>{
                return <li key={`submenu-${n}`}><a href={`${data.categories.path}/${i.uri}`}><span>{i.nombre}</span></a></li>
            })
        }
    };

    return <div className="hamburguerBody">
        <ul className="hamburguerContent">
            {primary()}
            <li>
                <span onClick={e=>{setShowSubmenu((showSubmenu === 1) ? 0 : 1)}}>Categorias</span>
                <BsPlus />
                <ul className={classNames({ 'active': (showSubmenu === 1)  })}>
                    {categories()}
                </ul>
            </li>
            <li>
                <span onClick={e=>{setShowSubmenu((showSubmenu === 2) ? 0 : 2)}}>Tipos de flor</span>
                <BsPlus />
                <ul className={classNames({ 'active': (showSubmenu === 2)  })}>
                    {tipos()}
                </ul>
            </li>
            <li>
                <span onClick={e=>{setShowSubmenu((showSubmenu === 3) ? 0 : 3)}}>Por ocaciones</span>
                <BsPlus />
                <ul className={classNames({ 'active': (showSubmenu === 3) })}>
                    {ocacion()}
                </ul>
            </li>
        </ul>
        <ul className="hamburguerContent hamburguerContent-second">
            <li>
                <Link href="/cuenta">
                    <a>Mi cuenta</a>
                </Link>
            </li>
            <li>
                <Link href="/compra">
                    <a>Carrito de compra</a>
                </Link>
            </li>
            <li>
                <Link href="/busqueda">
                    <a>Busqueda</a>
                </Link>
            </li>
            <li>
                <Link href="/contacto">
                    <a>Contacto</a>
                </Link>
            </li>
        </ul>

    </div>
}

export default Hamburguer;