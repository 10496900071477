import Image from 'next/image';
import Link from 'next/link';
import { FiUser, FiSearch, FiShoppingBag, FiMenu, FiChevronsUp, FiChevronDown } from 'react-icons/fi';
import {VscChromeClose, VscMenu} from 'react-icons/vsc';
import {useState, useEffect} from 'react';
import Hamburguer from './hamburguer'
import classNames from "classnames";
import axios from 'axios';

const Header = (props)=>{
    const [submenu, setSubmenu] = useState(null);
    const [hamburguerOpen, setHamburguerOpen] = useState(false);
    const [numberCart, setNumberCart] = useState(0);

    useEffect(()=>{
        axios.get('/api/get-cart-cookie')
            .then( response =>{
                if(response?.data?.cart?.products){
                    setNumberCart(response.data.cart.products.length);
                }
            })
            .catch( (error)=>{

            })
    },[])
    const Submenu = ()=>{
        if(submenu.data){
            return (<div className="headerSubmenu" onMouseLeave={()=>setSubmenu(null)}>
                <ul className="headerSubmenuContainer container">
                    { submenu.data.map( (i,n)=> {
                        let id = (i.id_categoria) ? i.id_categoria : (i.id_ocacion) ? i.id_ocacion : (i.id_tipoflor) ? i.id_tipoflor : `submenu-${n}`;
                        return <li key={id}>
                            <Link href={`${submenu.path}/${i.uri}`}>
                                <a>{i.nombre}</a>
                            </Link>
                        </li>
                    })
                    }
                </ul>
                <div className="headerSubmenuClose container" >
                    <div onClick={()=>setSubmenu(null)}>
                        <span>CERRAR</span>
                        <FiChevronsUp size={18}/>
                    </div>
                </div>
            </div>)
        }
    }

    const primary = ()=>{
        if( props.menu){
            return props.menu.primary.map((i,n)=>{
                return <li key={`menu-${n}`}><Link href={i.uri}><a>{i.nombre}</a></Link></li>
            });
        }
    };

    const categories = ()=>{
        return <li onMouseEnter={()=>{setSubmenu(props.menu.categories);}}>Categorias<FiChevronDown/></li>
    };
    const tipos = ()=>{
        return <li onMouseEnter={()=>{setSubmenu(props.menu.types);}}>Tipos de flor<FiChevronDown/></li>
    };
    const ocacion = ()=>{
        return <li onMouseEnter={()=>{setSubmenu(props.menu.ocacion);}}>Por ocasión<FiChevronDown/></li>
    };

    return (
        <>
            <header className="header">
                <div className="headerWrapper container">
                    <div className="headerMobile">
                        <div className="headerMobileButton" onClick={e=>{ setHamburguerOpen(true) }}>
                            <VscMenu />
                        </div>
                    </div>
                    <div className="headerLogo">
                        <Link href="/">
                            <a>
                                <Image
                                    src="/logo.svg"
                                    width={82}
                                    height={82}
                                />
                            </a>
                        </Link>
                    </div>
                    <ul className="headerMenu">
                        { (props.menu) && primary()  }
                        { (props.menu) && categories()  }
                        { (props.menu) && ocacion()  }
                        { (props.menu) && tipos()  }
                    </ul>
                    <ul className="headerPrincipal">
                        <li>
                            <Link href="/busqueda">
                                <a>
                                    <FiSearch size={24}/>
                                </a>
                            </Link>
                        </li>
                        <li>
                            <Link href="/cuenta">
                                <a>
                                    <FiUser size={24}/>
                                </a>
                            </Link>
                        </li>
                        <li>
                            <Link href="/compra">
                                <a>
                                    <FiShoppingBag size={24}/>
                                    { numberCart>0 && <span className="headerBagde">{numberCart}</span> }
                                </a>
                            </Link>
                        </li>
                    </ul>
                </div>
                {(submenu) && <Submenu/>}
            </header>
            <div className={classNames("hamburguer", {"hamburguer-open": (hamburguerOpen) })}>
                <div className="hamburguerHead">
                    <Link href="/">
                        <img src="/logo.svg" alt="Detalles y Caprichos"/>
                    </Link>
                    <span className="hamburguerClose" onClick={e=>{ setHamburguerOpen(false)} }>
                        <VscChromeClose />
                    </span>
                </div>
                <Hamburguer data={props.menu}/>
            </div>

        </>
    )
};

export default Header;
