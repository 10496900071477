import fecth from 'isomorphic-fetch';
import {FaAngleRight} from 'react-icons/fa';
import { useForm} from 'react-hook-form';

const Newsletter = ()=>{
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isSubmitSuccessful },
    } = useForm();

    const registerNewsletter = async ()=>{
      const res = await fecth('/api/newsletter', {
          body: JSON.stringify({
              email:  event.target.email.value
          }),
          headers: {
              'Content-Type': 'application/json'
          },
          method: 'POST'
      });
      return await res.json();
    };

    return (
        <section className="newsletter">
            <div className="container">
                <h5>¡RECIBE TU DESCUENTO DE BIENVENIDA!</h5>
                {(isSubmitSuccessful) ?
                    <div className="newsletterSubmited">
                        <p>Bienvenido a Detalles y Caprichos!, <br/> Escribe el cupón <code>BIENVENIDO</code> en tu carrito y obtén el descuento</p>
                    </div>
                    :
                    <>
                        <p>Suscríbete a nuestro newsletter y entérate antes que nadie de las mejores ofertas y contenido exclusivo</p>
                        <form className="newsletterForm" onSubmit={handleSubmit(registerNewsletter)} >
                            <input placeholder="Escribe tu correo" autoComplete="off" {...register('email', {
                                required: { value: true, message: 'Escribe tu email' },
                                pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: 'Escribe un email valido'
                                }
                            })} />
                            <span className="newsletterError">{errors?.email?.message}</span>
                            {(isSubmitting) ?
                                <div className="newsletterLoading"><p>Espera un momento...</p></div>
                                : <button type="submit" className="button" >
                                    Registrate <FaAngleRight/>
                                </button> }
                        </form>
                    </>
                }
            </div>
        </section>
    )

};

export default Newsletter;
